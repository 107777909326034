<template>
    <v-container>
        <div class="d-flex flex-column align-center justify-center mt-2">
            <div class="d-inline-flex align-center" style="display: block;width: 800px">
                <div>
                    <v-radio-group v-model="langSelection" hide-details row>
                        <v-radio
                                label="ไทย"
                                :value="1"
                        ></v-radio>
                        <v-radio
                                label="한국"
                                :value="2"
                        ></v-radio>
                    </v-radio-group>
                </div>
                <v-spacer></v-spacer>
                <div>
                    <v-btn v-print="printObj" color="primary">สั่งพิมพ์เอกสาร</v-btn>
                </div>
            </div>
            <div id="printMe" class="setfont" v-if="langSelection===1">
                <div v-for="(item,index) in dataPrint" :key="index" class="elevation-4 mt-2 rounded-lg"
                     style="background: #fff; width: 210mm;height: 297mm;padding:30px 30px 30px 30px">
                    <v-row no-gutters>
                        <v-col cols="2">
                            <img :src="shopData.shop_image" width="110px" alt="">
                        </v-col>
                        <v-col cols="10" class="ps-2">
                            <h1>
                                {{ shopData.shop_name }}
                            </h1>
                            <h3>
                                {{ shopData.shop_address_ko }}
                            </h3>
                            <h3>
                                เลขประจำตัวผู้เสียภาษี : {{ shopData.shop_tax_number }}
                            </h3>
                            <h3>
                                เบอร์โทรศัพท์ : {{ shopData.shop_phone }}
                            </h3>
                        </v-col>
                    </v-row>
                    <hr style="background: #000;height: 3px;margin-bottom: 4px">
                    <v-row no-gutters>
                        <v-col cols="8">
                            <h3>
                                ชื่อลูกค้า : {{ item.order_transportation_name }}
                            </h3>
                            <h3>
                                ที่อยู่ : {{ item.order_transportation_full_address }}
                            </h3>
                            <h3>
                                เบอร์โทรศัพท์ : {{ item.order_transportation_phone }}
                            </h3>
                        </v-col>
                        <v-col cols="4" style="text-align: right">
                            <h3>
                                ใบเสร็จรับเงิน
                            </h3>
                            <h3>
                                RECEIPT
                            </h3>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <h3>
                                เลขที่ออเดอร์ : {{ item.order_code }}
                            </h3>
                        </v-col>
                        <v-col cols="6" style="text-align: right">
                            <h3>
                                วันที่ : {{ item.create_date_text }}
                            </h3>
                        </v-col>
                    </v-row>
                    <hr style="background: #000;height: 3px;margin-bottom: 4px">
                    <v-row no-gutters>
                        <v-col cols="4" style="font-weight: bold">รายการ</v-col>
                        <v-col style="font-weight: bold;text-align: center">หน่วย</v-col>
                        <v-col style="font-weight: bold;text-align: right">จำนวน</v-col>
                        <v-col style="font-weight: bold;text-align: right">ราคาหน่วย</v-col>
                        <v-col style="font-weight: bold;text-align: right">จำนวนเงิน</v-col>
                        <v-col style="font-weight: bold;text-align: right">ภาษี</v-col>
                        <v-col style="font-weight: bold;text-align: right">ส่วนลด</v-col>
                        <v-col style="font-weight: bold;text-align: center">หมายเหตุ</v-col>
                    </v-row>
                    <hr style="background: #000;height: 3px;margin-bottom: 4px">
                    <v-row no-gutters v-for="(i,x) in item.order_detail_vat_arr">
                        <v-col cols="4" class="bb-1">{{ x + 1 }}. {{ i[2] }}</v-col>
                        <v-col class="bb-1" style="text-align: center">{{ i[3] }}</v-col>
                        <v-col class="bb-1" style="text-align: right">{{ i[4] }}</v-col>
                        <v-col class="bb-1" style="text-align: right">{{ i[5] }}</v-col>
                        <v-col class="bb-1" style="text-align: right">{{ i[6] }}</v-col>
                        <v-col class="bb-1" style="text-align: right">{{ i[7] }}</v-col>
                        <v-col class="bb-1" style="text-align: right">{{ i[8] }}</v-col>
                        <v-col class="bb-1" style="text-align: center">{{ i[9] }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <h3>หมายเหตุ </h3> {{ item.order_transportation_note }}
                        </v-col>
                        <v-col cols="6">
                            <v-row no-gutters>
                                <v-col cols="6" style="text-align: right">
                                    <h3>ราคารวม</h3>
                                </v-col>
                                <v-col cols="6" style="text-align: right">
                                    <h3>{{ item.order_before_discount | formatPrice }}</h3>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="6" style="text-align: right">
                                    <h3>ส่วนลด</h3>
                                </v-col>
                                <v-col cols="6" style="text-align: right">
                                    <h3>{{ item.order_discount_value | formatPrice }}</h3>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="6" style="text-align: right">
                                    <h3>ราคารวมหลังหักส่วนลด</h3>
                                </v-col>
                                <v-col cols="6" style="text-align: right">
                                    <h3>{{ item.order_after_discount | formatPrice }}</h3>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="6" style="text-align: right">
                                    <h3>ภาษีมูลค่าเพิ่ม ({{ item.order_vat }})%</h3>
                                </v-col>
                                <v-col cols="6" style="text-align: right">
                                    <h3>{{ item.order_vat_value | formatPrice }}</h3>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="6" style="text-align: right">
                                    <h3>ค่าขนส่ง</h3>
                                </v-col>
                                <v-col cols="6" style="text-align: right">
                                    <h3>{{ item.order_transportation_total | formatPrice }}</h3>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="6" style="text-align: right">
                                    <h3>ราคาสุทธิ</h3>
                                </v-col>
                                <v-col cols="6" style="text-align: right">
                                    <h3>{{ item.sum_total_transportation | formatPrice }}</h3>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <br>
                </div>
            </div>
            <div id="printMe" class="setfont" v-if="langSelection===2">
                <div v-for="(item,index) in dataPrint" :key="index" class="elevation-4 mt-2 rounded-lg"
                     style="background: #fff; width: 210mm;height: 297mm;padding:30px 30px 30px 30px">
                    <v-row no-gutters>
                        <v-col cols="2">
                            <img :src="shopData.shop_image" width="110px" alt="">
                        </v-col>
                        <v-col cols="10" class="ps-2">
                            <h1>
                                {{ shopData.shop_name }}
                            </h1>
                            <h3>
                                {{ shopData.shop_address_ko }}
                            </h3>
                            <h3>
                                세금식별번호 : {{ shopData.shop_tax_number }}
                            </h3>
                            <h3>
                                전화번호 : {{ shopData.shop_phone }}
                            </h3>
                        </v-col>
                    </v-row>
                    <hr style="background: #000;height: 3px;margin-bottom: 4px">
                    <v-row no-gutters>
                        <v-col cols="8">
                            <h3>
                                고객 : {{ item.order_transportation_name }}
                            </h3>
                            <h3>
                                사업장 : {{ item.order_transportation_full_address }}
                            </h3>
                            <h3>
                                전화번호 : {{ item.order_transportation_phone }}
                            </h3>
                        </v-col>
                        <v-col cols="4" style="text-align: right">
                            <h3>
                                영수증
                            </h3>
                            <h3>
                                RECEIPT
                            </h3>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <h3>
                                숫자 : {{ item.order_code }}
                            </h3>
                        </v-col>
                        <v-col cols="6" style="text-align: right">
                            <h3>
                                데이트 : {{ item.create_date_text }}
                            </h3>
                        </v-col>
                    </v-row>
                    <hr style="background: #000;height: 3px;margin-bottom: 4px">
                    <v-row no-gutters>
                        <v-col cols="4" style="font-weight: bold">품 목</v-col>
                        <v-col style="font-weight: bold;text-align: center">규 격</v-col>
                        <v-col style="font-weight: bold;text-align: right">수 량</v-col>
                        <v-col style="font-weight: bold;text-align: right">단 가</v-col>
                        <v-col style="font-weight: bold;text-align: right">공급가액</v-col>
                        <v-col style="font-weight: bold;text-align: right">세 액</v-col>
                        <v-col style="font-weight: bold;text-align: right">할인
                        </v-col>
                        <v-col style="font-weight: bold;text-align: center">비 고</v-col>
                    </v-row>
                    <hr style="background: #000;height: 3px;margin-bottom: 4px">
                    <v-row no-gutters v-for="(i,x) in item.order_detail_vat_arr">
                        <v-col cols="4" class="bb-1">{{ x + 1 }}. {{ i[2] }}</v-col>
                        <v-col class="bb-1" style="text-align: center">{{ i[3] }}</v-col>
                        <v-col class="bb-1" style="text-align: right">{{ i[4] }}</v-col>
                        <v-col class="bb-1" style="text-align: right">{{ i[5] }}</v-col>
                        <v-col class="bb-1" style="text-align: right">{{ i[6] }}</v-col>
                        <v-col class="bb-1" style="text-align: right">{{ i[7] }}</v-col>
                        <v-col class="bb-1" style="text-align: right">{{ i[8] }}</v-col>
                        <v-col class="bb-1" style="text-align: center">{{ i[9] }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <h3>비 고 </h3> {{ item.order_transportation_note }}
                        </v-col>
                        <v-col cols="6">
                            <v-row no-gutters>
                                <v-col cols="6" style="text-align: right">
                                    <h3>총 가격 </h3>
                                </v-col>
                                <v-col cols="6" style="text-align: right">
                                    <h3>{{ item.order_before_discount | formatPrice }}
                                        <CurrentCurrency/>
                                    </h3>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="6" style="text-align: right">
                                    <h3>할인</h3>
                                </v-col>
                                <v-col cols="6" style="text-align: right">
                                    <h3>{{ item.order_discount_value | formatPrice }}
                                        <CurrentCurrency/>
                                    </h3>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="6" style="text-align: right">
                                    <h3>할인 후 총 가격</h3>
                                </v-col>
                                <v-col cols="6" style="text-align: right">
                                    <h3>{{ item.order_after_discount | formatPrice }}
                                        <CurrentCurrency/>
                                    </h3>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="6" style="text-align: right">
                                    <h3>큰 통 ({{ item.order_vat }})%</h3>
                                </v-col>
                                <v-col cols="6" style="text-align: right">
                                    <h3>{{ item.order_vat_value | formatPrice }}
                                        <CurrentCurrency/>
                                    </h3>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="6" style="text-align: right">
                                    <h3>화물</h3>
                                </v-col>
                                <v-col cols="6" style="text-align: right">
                                    <h3>{{ item.order_transportation_total | formatPrice }}
                                        <CurrentCurrency/>
                                    </h3>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="6" style="text-align: right">
                                    <h3>정가</h3>
                                </v-col>
                                <v-col cols="6" style="text-align: right">
                                    <h3>{{ item.sum_total_transportation | formatPrice }}
                                        <CurrentCurrency/>
                                    </h3>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <br>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
import print from 'vue-print-nb'
import { onMounted, ref, watch } from "@vue/composition-api"
import { api } from "@/services/api";
import { useRouter } from "@core/utils";
import { formatPrice } from "@/services/filters";
import CurrentCurrency from "@/components/CurrentCurrency.vue"

export default {
    directives: {
        print
    },
    props: {},
    components: { CurrentCurrency, },
    filters: {
        formatPrice
    },
    setup(_, { root, emit }) {
        const { route } = useRouter()
        const printLoading = ref(true)
        const dataPrint = ref([])
        const shopData = ref({})
        const langSelection = ref(2)
        const printObj = ref({
            id: "printMe",
            popTitle: '',
            extraCss: 'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700&display=swap,https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css',
            extraHead: '',
            beforeOpenCallback(vue) {
                vue.printLoading = true
                console.log('打开之前')
            },
            openCallback(vue) {
                vue.printLoading = false
                console.log('执行了打印')
            },
            closeCallback(vue) {
                console.log('关闭了打印工具')
            }
        })
        onMounted(() => {
            shopData.value = JSON.parse(localStorage.getItem('shop'))
            getPrintData('ko')
        })

        const getPrintData = (lang) => {
            api.get({
                path: '/printOrder',
                param: `?order_id_list=${ route.value.query.id }&lang=${ lang }&currency_id=${ localStorage.getItem('currencyId') }`,
            }).then(({ data }) => {
                dataPrint.value = data
                console.log('data:', data[0])
            }).catch((err) => {
                console.log(err)
            })
        }

        watch(langSelection, value => {
            if (value === 1) {
                getPrintData('th')
            } else {
                getPrintData('ko')
            }

        })


        return { printLoading, printObj, dataPrint, langSelection, shopData }
    },


}
</script>

<style scoped>
@media print {
    @page {
        size: 210mm 297mm;
        margin: 0;
    }
}

.setfont {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 14px;
    color: #000;
}

. {
    border-left: 1px solid #000;
}

.br-1 {
    border-right: 1px solid #000;
}

.bt-1 {
    border-top: 1px solid #000;
}

.bb-1 {
    border-bottom: 1px solid #000;
}

.b-1 {
    border: 1px solid #000;
}

</style>

