/*
 * string format  "2021-05-01" only Date
 */

function formatPrice(value) {
    if (value || value === 0) {
        const val = (value / 1).toFixed(2).replace(".", ".");

        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

function calBonus(value,) {


}

function sumdatetime(string) {
    let d = new Date(string);
    if (string && string != "-") {
        const year = d.getFullYear()
        const sumYear = d.getFullYear() + 543;
        const mount = d.getMonth();
        const day = d.getDate();
        const time = (d.getHours() < 10 ? '0' : '') + d.getHours() + ":" + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
        const months_th_mini = [
            "ม.ค.",
            "ก.พ.",
            "มี.ค.",
            "เม.ย.",
            "พ.ค.",
            "มิ.ย.",
            "ก.ค.",
            "ส.ค.",
            "ก.ย.",
            "ต.ค.",
            "พ.ย.",
            "ธ.ค.",
        ];
        const date = `${ day } ${ months_th_mini[mount] } ${ sumYear }  ${ time }`;

        return date;
    }

    return string;
}

function sumdate(string) {
    let d = new Date(string);
    if (string != null) {
        const year = d.getFullYear()
        const sumYear = d.getFullYear() + 543;
        const mount = d.getMonth();
        const day = d.getDate();
        const months_th_mini = [
            'ม.ค.',
            'ก.พ.',
            'มี.ค.',
            'เม.ย.',
            'พ.ค.',
            'มิ.ย.',
            'ก.ค.',
            'ส.ค.',
            'ก.ย.',
            'ต.ค.',
            'พ.ย.',
            'ธ.ค.',
        ]

        const date = `${ day } ${ months_th_mini[mount] } ${ sumYear }`

        return date
    }

    return string
}

function conversType(type) {
    if (type == "deposit") {
        return "ฝาก";
    } else if (type == "withdraw") {
        return "ถอน";
    } else if (type == "bonus") {
        return "โบนัส";
    }
}

function conversStatus(status) {
    if (status == "pending") {
        return "กำลังดำเนินการ";
    } else if (status == "success") {
        return "สำเร็จ";
    } else {
        return "ผิดพลาด";
    }
}

function convertMoney(num) {
    return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(3) + "k"
        : formatPrice(num);
}

function getByDate(dateText = new Date()) {
    if (!dateText) return null;
    const d = new Date(dateText);
    const dISO = d.toISOString();
    const re1 = dISO.replace("T", " ");
    const re2 = re1.replace("Z", "");

    let month = (d.getMonth() + 1) < 10 ? `0${ d.getMonth() + 1 }` : d.getMonth() + 1;
    let day_date = d.getDate() < 10 ? `0${ d.getDate() }` : d.getDate();
    const date = `${ d.getFullYear() }-${ month }-${ day_date }`;
    let hours = d.getHours() >= 10 ? d.getHours() : `0${ d.getHours() }`;
    let minutes = d.getMinutes() >= 10 ? d.getMinutes() : `0${ d.getMinutes() }`;
    let seconds = d.getSeconds() >= 10 ? d.getSeconds() : `0${ d.getSeconds() }`;
    const time_now = `${ hours }:${ minutes }:${ seconds }`;

    return {
        timestamp: re2,
        date: date,
        time_now: time_now,
        dateTime: date + ' ' + time_now
    };
}

async function removeComma(data) {
    try {
        const response = data.map(item => {
            for (const [key, value] of Object.entries(item)) {
                if (
                    `${ item[key] }`.includes('.') &&
                    /([0-9])\.[0-9][0-9]/.test(`${ item[key] }`) &&
                    !/([ก-๙A-Za-z])/.test(`${ item[key] }`)
                ) {
                    item[key] = parseFloat(`${ item[key] }`.split(',').join(''))
                }
            }

            return item
        })

        return response
    } catch (err) {
        console.log(err)
    }
}

function sumOrderTotal(data) {
    const {
        store_product_price,
        order_detail_tmp_amount,
        order_detail_tmp_discount,
        order_detail_tmp_vat,
        order_detail_tmp_price
    } = data
    let total = order_detail_tmp_amount * parseFloat(`${ store_product_price || order_detail_tmp_price }`.split(',').join(''))
    let discount = order_detail_tmp_discount * total / 100
    let vat = order_detail_tmp_vat * (total - discount) / 100
    return formatPrice((total + vat) - (discount))
}

function sumOrderTotalVat(data) {
    const {
        store_product_price,
        order_detail_tmp_amount,
        order_detail_tmp_discount,
        order_detail_tmp_vat,
        order_detail_tmp_price
    } = data
    let total = order_detail_tmp_amount * parseFloat(`${ store_product_price || order_detail_tmp_price }`.split(',').join(''))
    let discount = order_detail_tmp_discount * total / 100
    let vat = order_detail_tmp_vat * (total - discount) / 100
    return formatPrice(vat)
}

function sumOrderTotal2(data) {
    const {
        store_product_price,
        order_detail_tmp_amount,
        order_detail_tmp_discount,
        order_detail_tmp_vat,
        order_detail_tmp_price
    } = data
    let total = order_detail_tmp_amount * parseFloat(`${ store_product_price || order_detail_tmp_price }`.split(',').join(''))

    let discount = +order_detail_tmp_discount.promotion_discount_amount ? order_detail_tmp_discount.promotion_discount_amount : 0
    let vat = +order_detail_tmp_vat * (total - discount) / 100
    return formatPrice((total + vat) - (discount))
}

function sumOrderTotal3(data) {
    const {
        store_product_price,
        order_detail_tmp_amount,
        order_detail_tmp_price
    } = data
    let total = order_detail_tmp_amount * parseFloat(`${ store_product_price || order_detail_tmp_price }`.split(',').join(''))
    return formatPrice(total)
}

function sumOrderTotal4(data) {
    const {
        store_product_price,
        order_detail_tmp_amount,
        order_detail_tmp_discount,
        order_detail_tmp_price
    } = data
    let total = order_detail_tmp_amount * parseFloat(`${ store_product_price || order_detail_tmp_price }`.split(',').join(''))
    let discount = total * order_detail_tmp_discount / 100
    return formatPrice(discount)
}

function sumOrderTotalVat2(data) {
    const {
        store_product_price,
        order_detail_tmp_amount,
        order_detail_tmp_discount,
        order_detail_tmp_vat,
        order_detail_tmp_price
    } = data
    let total = order_detail_tmp_amount * parseFloat(`${ store_product_price || order_detail_tmp_price }`.split(',').join(''))
    let vat = (total - order_detail_tmp_discount.promotion_discount_amount || 0) * order_detail_tmp_vat / 100
    return formatPrice(vat)
}


function convertStingToNumber(text) {
    return parseFloat(String(text).split(',').join(''))
}

function currentCurrency() {
    if (localStorage.getItem('currency') == 'THB') {
        return '฿'
    } else if (localStorage.getItem('currency') == 'USD') {
        return '$'
    } else {
        return '₩'
    }
}

export {
    sumOrderTotalVat,
    currentCurrency,
    convertStingToNumber,
    sumOrderTotal2,
    formatPrice,
    sumdatetime,
    conversType,
    conversStatus,
    convertMoney,
    sumdate,
    getByDate,
    removeComma,
    sumOrderTotal,
    sumOrderTotal3,
    sumOrderTotal4,
    sumOrderTotalVat2,

};
